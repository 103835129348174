import Layout from '@/layout/index';

const router = [
  {
    path: '/ui-standard',
    component: Layout,
    meta: { auth: true, title: 'UI规范', name: 'uiStandard', icon: 'vh-icon-s-help' },
    hidden: true,
    children: [
      {
        path: 'page-create',
        component: () => import('@/views/ui-standard/PageCreate.vue'),
        meta: {
          appCls: 'std-app',
          auth: true,
          title: '标准创建页',
          name: 'uiStandardPageCreate',
          icon: 'table'
        }
      },
      {
        path: 'page-list',
        component: () => import('@/views/ui-standard/PageList.vue'),
        meta: {
          appCls: 'std-app',
          auth: true,
          title: '标准列表页无Tab',
          name: 'uiStandardPageList',
          icon: 'table'
        }
      },
      {
        path: 'page-list2',
        component: () => import('@/views/ui-standard/PageList2.vue'),
        meta: {
          appCls: 'std-app',
          auth: true,
          title: '标准列表页有Tab',
          name: 'uiStandardPageList2',
          icon: 'table'
        }
      },
      {
        path: 'page-dialog',
        component: () => import('@/views/ui-standard/PageDialog.vue'),
        meta: {
          appCls: 'std-app',
          auth: true,
          title: '弹窗大集合',
          name: 'uiStandardPageDialog',
          icon: 'table'
        }
      }
    ]
  }
];
export default router;
