import home from './home.js';
import interactive from './interactive.js';
import liveOrSpecial from './liveOrSpecial.js';
import material from './material.js';
import setting from './setting.js';
import user from './user.js';
import other from './other.js';
import data from './data.js';
import AI from './AI.js';
import uiStandard from './ui-standard.js';
export default [
  ...home,
  ...liveOrSpecial,
  ...interactive,
  ...AI,
  ...material,
  ...data,
  ...setting,
  ...user,
  ...other,
  ...uiStandard
];
